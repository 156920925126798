import { template as template_2af0c4457fc24ae7b4b739222fd9d6ba } from "@ember/template-compiler";
const FKText = template_2af0c4457fc24ae7b4b739222fd9d6ba(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
