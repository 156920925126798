import { template as template_89a983459aa64f75a958c677c89ee77c } from "@ember/template-compiler";
const FKLabel = template_89a983459aa64f75a958c677c89ee77c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
