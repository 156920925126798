import { template as template_8b83824d2f29426cb5522214271e8c71 } from "@ember/template-compiler";
const WelcomeHeader = template_8b83824d2f29426cb5522214271e8c71(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
