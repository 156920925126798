import { template as template_86e908ad94684ce7939ecc9911673dbd } from "@ember/template-compiler";
const FKControlMenuContainer = template_86e908ad94684ce7939ecc9911673dbd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
