import { template as template_9b548e3de79a48b9b319f2fdf2611a9a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_9b548e3de79a48b9b319f2fdf2611a9a(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
